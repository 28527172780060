export const recommendationsData = [
  {
    value: "An event Professional",
    pageBg: "./media/recommendations/pageBg2.jpg",
    pageColorSheme: "light",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "New to the events industry",
    pageBg: "./media/recommendations/pageBg3.jpg",
    pageColorSheme: "light",
    buttonLink: "https://www.amazon.com/",
    boxes: [
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "A graduate",
    pageBg: "./media/recommendations/pageBg4.jpg",
    pageColorSheme: "light",
    buttonLink: "https://www.thepowerofevents.org/",
    boxes: [
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "Looking to join the events industry",
    pageBg: "./media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "A client, investor or funder",
    pageBg: "./media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "From Government",
    pageBg: "./media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "From National Press",
    pageBg: "./media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
  {
    value: "Just exploring",
    pageBg: "./media/recommendations/pageBg1.jpg",
    pageColorSheme: "dark",
    buttonLink: "https://www.google.com/",
    boxes: [
      {
        title: "Universities",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test",
        bgImage: "./media/recommendations/bg2.jpeg",
        icon: "./media/recommendations/icon2-podcasts.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test2",
        bgImage: "./media/recommendations/bg3.jpeg",
        icon: "./media/recommendations/icon3-help.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test3",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
      {
        title: "Test4",
        bgImage: "./media/recommendations/bg1.jpeg",
        icon: "./media/recommendations/icon1-online-communities.png",
        url: "https://www.google.com/",
      },
    ],
  },
];
