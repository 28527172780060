import { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import gsap from "gsap";
import logo from "../media/PowerOfEventsLogoMix.png";

export default function Intro({
  setIsInitScreen,
  setIsIntroVideoScreen,
  setIsMainScreen,
  isMuted,
  isPlaying,
  setIsPlaying,
  handleVideoEnd,
}) {
  const videoFile = useRef();
  const videoUrl = "./media/intro.mp4";
  const loadingButton = useRef();

  // let videoData = {
  //   playing: false,
  //   muted: false,
  //   played: 0,
  //   loaded: 0,
  //   duration: 0,
  // };

  const [isReady, setIsReady] = useState(false);
  const tlButton = gsap.timeline({ repeat: -1 });

  useEffect(() => {
    loadingButton.current.innerHTML = loadingButton.current.innerText.replace(
      /\w/g,
      "<span>$&</span>"
    );

    if (
      loadingButton.current.classList.contains("loading-screen__text--loading")
    ) {
      tlButton.from(
        ".loading-screen__text--loading span",
        {
          duration: 0.4,
          alpha: 0,
          x: 40,
          stagger: {
            each: 0.1,
          },
        },
        "+=0.5"
      );
      tlButton.to(
        ".loading-screen__text--loading span",
        {
          duration: 0.4,
          alpha: 0,
          x: -40,
          stagger: {
            each: 0.1,
          },
        },
        "+=0.8"
      );
    } else {
      tlButton.from(".loading-screen__text--play span", {
        duration: 1.2,
        alpha: 0.2,
      });
      tlButton.to(".loading-screen__text--play span", {
        duration: 0.3,
        alpha: 1,
      });
      tlButton.to(".loading-screen__text--play span", {
        duration: 1.2,
        alpha: 0.2,
      });
    }
  });

  // useEffect(() => {
  //   setIsPlaying(false);
  // }, []);

  // const onProgress = (event) => {
  //   // if (event.loaded === 1) {
  //   //   setIsReady(true);
  //   // }
  // };

  let count = 0;
  const counter = () => {
    count = count + 1;
    setTimeout(counter, 1000);
    if (count > 5) {
      return setIsReady(true);
    }
  };
  counter();

  const onReady = () => {
    count > 5 && setIsReady(true);
  };

  const playVideo = () => {
    if (isReady) {
      const tlBg = gsap.timeline({ repeat: 0 });

      // tlBg.from(".loading-screen", {
      //   //background: "#000",
      //   filter: "blur(10px)",
      //   duration: 2,
      // });
      // tlBg.to(".loading-screen", {
      //   background:
      //     "linear-gradient(45deg, rgba(36,150,208,1) 0%, rgba(177,35,51,1) 32%, rgba(126,15,42,1) 60%, rgba(170,55,97,1) 100%)",
      //   duration: 2,
      // });
      // tlBg.from(
      //   ".loading-screen__text--play span",
      //   {
      //     duration: 0.4,
      //     alpha: 1,
      //     y: 0,
      //     stagger: {
      //       each: 0.1,
      //     },
      //   },
      //   "+=0.5"
      // );
      tlButton.pause();
      tlBg.to(
        ".loading-screen__text--play span",
        {
          duration: 0.4,
          alpha: 0,
          y: 40,
          stagger: {
            each: 0.1,
          },
        },
        "+=0.8"
      );
      gsap.to(".loading-screen__container", {
        filter: "blur(100px)",
        alpha: 0,
        duration: 1.5,
        ease: "power2.inOut",
        delay: 0.8,
      });
      gsap.to(".loading-screen", {
        right: "100%",
        duration: 1.5,
        delay: 1.8,
        ease: "power2.inOut",
      });
      // tlBg.to(".loading-screen", {
      //   filter: "blur(0px)",
      //   //filter: "blur(10px)",
      // });

      // gsap.to(".loading-screen", {
      //   clipPath: "circle(0%)",
      //   //opacity: 0,
      //   duration: 2,
      // });

      const triggerPlay = () => {
        setIsPlaying(true);
        setIsInitScreen(false);
        setIsIntroVideoScreen(true);
      };

      setTimeout(triggerPlay, 2000);
    }
  };

  return (
    <>
      <div className="loading-screen">
        <button
          className={`loading-screen__container ${
            isReady ? "loading-screen__container--link" : ""
          }`}
          onClick={playVideo}
        >
          <img className="loading-screen__logo" src={logo} alt="Logo" />
          <p
            ref={loadingButton}
            className={`loading-screen__text ${
              isReady
                ? "loading-screen__text--play"
                : "loading-screen__text--loading"
            }`}
          >
            {isReady ? "PLAY" : "LOADING"}
          </p>
        </button>
      </div>

      <div className="intro">
        <div className="intro__container">
          <ReactPlayer
            ref={videoFile}
            playing={isPlaying}
            muted={isMuted}
            //onStart={handlePreloadFull}
            onReady={onReady}
            onEnded={handleVideoEnd}
            //onProgress={onProgress}
            //playbackRate={10}
            controls={false}
            className="intro__video"
            url={videoUrl}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </>
  );
}
