import { useRef, useEffect } from "react";
import gsap from "gsap";

export default function Menu({ isNavOpen, setIsNavOpen }) {
  // Toggle top navigation
  const handleNavOpen = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  return (
    <nav className="nav">
      <button
        className={`hamburger ${isNavOpen ? "container--hidden" : ""}`}
        aria-expanded={isNavOpen ? "true" : "false"}
        onClick={handleNavOpen}
      >
        <span className="hamburger__line hamburger__line--line1"></span>
        <span className="hamburger__line hamburger__line--line2"></span>
        <span className="hamburger__line hamburger__line--line3"></span>
      </button>

      <div className="nav__bg-overlay"></div>
      <div className="nav__menu"></div>
    </nav>
  );
}
