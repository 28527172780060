import { useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";
import gsap from "gsap";
import Header from "./Header";
import Footer from "./Footer";
import Cursor from "./Cursor";

export default function Layout({
  app,
  appBg,
  isNavOpen,
  setIsNavOpen,
  isInitScreen,
  isIntroVideoScreen,
  isMainScreen,
  setIsMainScreen,
  isSingleModelPage,
  isRecommendationsScreen,
  setIsRecommendationsScreen,
  boxData,
  setBoxData,
  isMuted,
  setIsMuted,
  setIsPlaying,
  handleVideoEnd,
  setIsModelLinkOn,
}) {
  return (
    <>
      <div
        ref={app}
        className={`app ${isNavOpen ? "nav-opened" : ""}`}
        style={appBg}
      >
        <Header
          isInitScreen={isInitScreen}
          isIntroVideoScreen={isIntroVideoScreen}
          isMainScreen={isMainScreen}
          isSingleModelPage={isSingleModelPage}
          isNavOpen={isNavOpen}
          setIsNavOpen={setIsNavOpen}
          isRecommendationsScreen={isRecommendationsScreen}
          boxData={boxData}
        />
        <Outlet />
        <Footer
          isInitScreen={isInitScreen}
          isIntroVideoScreen={isIntroVideoScreen}
          isMainScreen={isMainScreen}
          isSingleModelPage={isSingleModelPage}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          setIsPlaying={setIsPlaying}
          handleVideoEnd={handleVideoEnd}
          isRecommendationsScreen={isRecommendationsScreen}
          setIsRecommendationsScreen={setIsRecommendationsScreen}
          setBoxData={setBoxData}
          setIsMainScreen={setIsMainScreen}
          setIsModelLinkOn={setIsModelLinkOn}
        />
        <Cursor app={app} isMainScreen={isMainScreen} />
      </div>
    </>
  );
}
