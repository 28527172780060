import { useState, useRef } from "react";
import { Html } from "@react-three/drei";
import sectionImageFlag from "../media/element-compass.png";
import sectionImageFlag2 from "../media/element2-compass.png";

export default function CompassLinks({ isModelLinkOn }) {
  return (
    <>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.68}
        position={[50.5, 466, 70]}
        // position={[56.95, -390, -85]}
        rotation-x={-Math.PI * 0.1184}
        rotation-y={-Math.PI * 0.645}
        rotation-z={-Math.PI * 0.066}
      >
        <a
          href="https://google.com/"
          className="model-link__link"
          link-pair="link1"
        >
          <img
            src={sectionImageFlag}
            alt="Support Organisations"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        // position={[63.95, 40, 615]}
        position={[85.5, 0.5, 654]}
        rotation-x={Math.PI * 0.561}
        rotation-y={-Math.PI * 0.315}
        rotation-z={Math.PI * 0.5}
      >
        <a
          href="https://google.com/"
          className="model-link__link"
          link-pair="link1"
        >
          <img
            src={sectionImageFlag2}
            alt="Support Organisations"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, 466, -70]}
        rotation-x={Math.PI * 0.1184}
        rotation-y={Math.PI * 0.645}
        rotation-z={-Math.PI * 0.066}
      >
        <a
          href="https://amazon.com/"
          className="model-link__link"
          link-pair="link2"
        >
          <img
            src={sectionImageFlag}
            alt="Associations"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, 0.5, -654]}
        rotation-x={-Math.PI * 0.561}
        rotation-y={Math.PI * 0.315}
        rotation-z={Math.PI * 0.5}
      >
        <a
          href="https://amazon.com/"
          className="model-link__link"
          link-pair="link2"
        >
          <img
            src={sectionImageFlag2}
            alt="Associations"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[50.5, -466, 70]}
        rotation-x={Math.PI * 0.1184}
        rotation-y={(Math.PI / 2) * 0.7}
        rotation-z={-Math.PI * 0.066 + Math.PI}
      >
        <a
          href="https://apple.com/"
          className="model-link__link"
          link-pair="link3"
        >
          <img
            src={sectionImageFlag}
            alt="Industry Media"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, -0.5, 654]}
        rotation-x={-Math.PI * 0.561}
        rotation-y={Math.PI * 2 * 0.342}
        rotation-z={-Math.PI * 0.5}
      >
        <a
          href="https://apple.com/"
          className="model-link__link"
          link-pair="link3"
        >
          <img
            src={sectionImageFlag2}
            alt="Industry Media"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>

      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        // position={[56.95, 390, -85]}
        // rotation-x={Math.PI * 0.1184}
        // rotation-y={Math.PI * 0.645}
        // rotation-z={-Math.PI * 0.066}
        position={[50.5, -466, -70]}
        rotation-x={-Math.PI * 0.1184}
        rotation-y={(-Math.PI / 2) * 0.7}
        rotation-z={-Math.PI * 0.066 + Math.PI}
      >
        <a
          href="https://google.com/"
          className="model-link__link"
          link-pair="link4"
        >
          <img
            src={sectionImageFlag}
            alt="Universities"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html
        transform
        zIndexRange={[1, 0]}
        wrapperClass="model-link"
        distanceFactor={100}
        scale={4.69}
        position={[85.5, -0.5, -654]}
        rotation-x={Math.PI * 0.561}
        rotation-y={-Math.PI * 2 * 0.342}
        rotation-z={-Math.PI * 0.5}
      >
        <a
          href="https://google.com/"
          className="model-link__link"
          link-pair="link4"
        >
          <img
            src={sectionImageFlag2}
            alt="Universities"
            className="model-link__image"
          />
          <div
            className={`model-link__shape model-link__shape--triangle ${
              !isModelLinkOn ? "model-link__shape--disabled" : ""
            }`}
          ></div>
        </a>
      </Html>
      <Html transform zIndexRange={[1, 0]} wrapperClass="model-link"></Html>
    </>
  );
}
