import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { ReactComponent as ArrowUp } from "../media/arrow-up.svg";

export default function Box({ boxData }) {
  const boxStyle = {
    backgroundImage: `url(${boxData.bgImage})`,
  };

  const boxHoverInAnim = (event) => {
    gsap.to(event.target, {
      duration: 0.2,
      y: -20,
      alpha: 0.7,
    });
  };

  const boxHoverOutAnim = (event) => {
    gsap.to(event.target, {
      duration: 0.2,
      y: 0,
      alpha: 1,
    });
  };

  return (
    <a
      href={boxData.url}
      style={boxStyle}
      className="box"
      onMouseEnter={boxHoverInAnim}
      onMouseLeave={boxHoverOutAnim}
    >
      <div className="box__title">{boxData.title}</div>
      <img className="box__icon" src={boxData.icon} alt={boxData.title} />
    </a>
  );
}
