import { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { Canvas, useThree } from "@react-three/fiber";
import { Loader } from "@react-three/drei";
import { Perf } from "r3f-perf";
import gsap from "gsap";
import Layout from "./components/Layout.js";
import Experience from "./components/Experience.js";
import ExperienceSingleHeart from "./components/ExperienceSingleHeart.js";
import Intro from "./components/Intro.js";
import Recommendations from "./components/Recommendations.js";

export default function App() {
  const app = useRef();
  const canvasEl = useRef();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isIntroVideoScreen, setIsIntroVideoScreen] = useState(false);
  const [isMainScreen, setIsMainScreen] = useState(false);
  const [isSingleModelPage, setIsSingleModelPage] = useState(false);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [isRecommendationsScreen, setIsRecommendationsScreen] = useState(false);
  const [isModelLinkOn, setIsModelLinkOn] = useState(false);
  const [appBg, setAppBg] = useState({
    backgroundImage: `url(${"./textures/main-bg.jpg"})`,
  });
  const [boxData, setBoxData] = useState({
    pageTitle: "",
    pageBg: "",
    pageColorSheme: "",
    buttonLink: "",
    boxes: [],
  });

  const toggleAppBgOnMobile = () => {
    isRecommendationsScreen
      ? setAppBg({
          backgroundImage: `url(${boxData.pageBg})`,
        })
      : setAppBg({
          backgroundImage: `url("./textures/main-bg.jpg"`,
        });
  };

  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isInitialLoadDone) {
      isRecommendationsScreen ? mainOutAnimation() : mainInAnimation();
      setTimeout(toggleAppBgOnMobile, 1000);
    }
  }, [isRecommendationsScreen]);

  // useEffect(() => {
  //   const canvasTopParent = canvasEl.current.parentNode.parentNode;
  //   gsap.set(canvasTopParent, {
  //     position: "absolute",
  //   });
  // }, []);

  const mainOutAnimation = () => {
    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    gsap.to(canvasTopParent, {
      alpha: 0,
      duration: 2,
      ease: "power2.inOut",
    });
    gsap.to(canvasTopParent, {
      delay: 1,
      display: "none",
    });
  };

  const mainInAnimation = () => {
    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    gsap.to(canvasTopParent, {
      //delay: 2,
      display: "block",
    });
    gsap.to(canvasTopParent, {
      alpha: 1,
      duration: 2,
      ease: "power2.inOut",
    });
  };

  const handleVideoEnd = () => {
    gsap.to(".intro", {
      filter: "blur(100px)",
      alpha: 0,
      duration: 3,
      ease: "power2.inOut",
    });
    gsap.to(".intro", {
      display: "none",
      delay: 3,
    });

    // setIsMuted(true);
    setIsPlaying(false);

    const setStatesOnVideoEnded = () => {
      setIsIntroVideoScreen(false);
      setIsMainScreen(true);
      setIsInitialLoadDone(true);
      setIsModelLinkOn(true);
    };

    setTimeout(setStatesOnVideoEnded, 3000);
  };

  return (
    <Routes>
      <Route
        element={
          <Layout
            app={app}
            appBg={appBg}
            isNavOpen={isNavOpen}
            setIsNavOpen={setIsNavOpen}
            isInitScreen={isInitScreen}
            isIntroVideoScreen={isIntroVideoScreen}
            isMainScreen={isMainScreen}
            setIsMainScreen={setIsMainScreen}
            isSingleModelPage={isSingleModelPage}
            isRecommendationsScreen={isRecommendationsScreen}
            setIsRecommendationsScreen={setIsRecommendationsScreen}
            boxData={boxData}
            setBoxData={setBoxData}
            isMuted={isMuted}
            setIsMuted={setIsMuted}
            setIsPlaying={setIsPlaying}
            handleVideoEnd={handleVideoEnd}
            setIsModelLinkOn={setIsModelLinkOn}
          />
        }
      >
        <Route
          path="/"
          element={
            <>
              <Intro
                setIsInitScreen={setIsInitScreen}
                setIsIntroVideoScreen={setIsIntroVideoScreen}
                setIsMainScreen={setIsMainScreen}
                isMuted={isMuted}
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                handleVideoEnd={handleVideoEnd}
              />
              <Canvas
                ref={canvasEl}
                shadows
                camera={{
                  //fov: 45,
                  near: 1,
                  far: 200,
                  zoom: 130,
                }}
                orthographic
              >
                <Experience
                  isPlaying={isPlaying}
                  isMainScreen={isMainScreen}
                  isRecommendationsScreen={isRecommendationsScreen}
                  setIsRecommendationsScreen={setIsRecommendationsScreen}
                  isInitialLoadDone={isInitialLoadDone}
                  setIsInitialLoadDone={setIsInitialLoadDone}
                  isModelLinkOn={isModelLinkOn}
                />
              </Canvas>
              <Recommendations
                isRecommendationsScreen={isRecommendationsScreen}
                setIsRecommendationsScreen={setIsRecommendationsScreen}
                boxData={boxData}
              />
            </>
          }
        />
        <Route
          path="/uk-events-industry/"
          element={
            <Canvas
              ref={canvasEl}
              shadows
              camera={{
                //fov: 45,
                near: 1,
                far: 200,
                zoom: 130,
              }}
              orthographic
            >
              <ExperienceSingleHeart
                isPlaying={isPlaying}
                setIsInitScreen={setIsInitScreen}
                setIsIntroVideoScreen={setIsIntroVideoScreen}
                setIsMainScreen={setIsMainScreen}
                isMainScreen={isMainScreen}
                isSingleModelPage={isSingleModelPage}
                setIsSingleModelPage={setIsSingleModelPage}
                isRecommendationsScreen={isRecommendationsScreen}
                setIsRecommendationsScreen={setIsRecommendationsScreen}
                isInitialLoadDone={isInitialLoadDone}
                setIsInitialLoadDone={setIsInitialLoadDone}
                isModelLinkOn={isModelLinkOn}
              />
            </Canvas>
          }
        />
      </Route>
    </Routes>
  );
}
