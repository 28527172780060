import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { ReactComponent as ArrowUp } from "../media/arrow-up.svg";
import Box from "./Box";
import { NoToneMapping } from "three";

export default function Recommendations({ boxData, isRecommendationsScreen }) {
  const buttonHoverAnim = (event) => {
    gsap.fromTo(
      event.target,
      {
        background:
          "linear-gradient(110deg,rgba(236, 24, 86, 1) 60%,rgba(106, 20, 74, 1) 100%)",
      },
      {
        ease: "none",
        duration: 1,
        background:
          "linear-gradient(270deg,rgba(106, 20, 74, 1) 40%,rgba(236, 24, 86, 1) 100%)",
        repeat: -1,
        yoyo: true,
      }
    );
  };

  useEffect(() => {
    //if (isInitialLoadDone) {
    gsap.to(".recommendations__boxes .box", {
      pointerEvents: "none",
    });
    isRecommendationsScreen ? boxesInAnimation() : boxesOutAnimation();
    //}
  }, [isRecommendationsScreen]);

  // useEffect(() => {
  //   gsap.set(".recommendations", {
  //     display: "none",
  //     filter: "blur(10px)",
  //   });
  // }, []);

  const addPointerEvents = () => {
    gsap.to(".recommendations__boxes .box", {
      delay: 1.5,
      pointerEvents: "auto",
    });
  };

  const boxesInAnimation = () => {
    if (window.innerWidth <= 960) {
      gsap.to(".recommendations", {
        delay: 1,
        display: "block",
      });
    }
    gsap.fromTo(
      ".recommendations__boxes .box",
      {
        y: -150,
        alpha: 0,
        filter: "blur(10px)",
      },
      {
        delay: 1,
        y: 0,
        alpha: 1,
        filter: "blur(0px)",
        stagger: {
          from: "center",
          each: 0.06,
        },
        onComplete: addPointerEvents(),
      }
    );
  };

  const boxesOutAnimation = () => {
    gsap.to(".recommendations__boxes .box", {
      delay: 0,
      y: -150,
      alpha: 0,
      filter: "blur(10px)",
      stagger: {
        from: "center",
        each: 0.06,
      },
    });
    if (window.innerWidth <= 960) {
      gsap.to(".recommendations", {
        display: "none",
      });
    }
  };

  // const recommendationsStyles = {
  //   display: `${isRecommendationsScreen ? "block" : "none"}`,
  // };

  const { buttonLink, pageColorSheme, boxes } = boxData;
  return (
    <div className="recommendations">
      <div className="container container--wide">
        <h1
          className="recommendations__title"
          style={{ color: pageColorSheme === "light" ? "#fff" : "#000" }}
        >
          Our Recommendations For You
        </h1>

        <div className="recommendations__boxes">
          {boxes.map((box, index) => (
            <Box
              title={box.title}
              boxData={box}
              key={index}
              // bgImage={box.bgImage}
              // icon={box.icon}
              // url={box.url}
              // key={index}
            />
          ))}
        </div>
        <a href={buttonLink} className="recommendations__button">
          New Entrant
        </a>
      </div>
    </div>
  );
}
