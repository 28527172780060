import { useEffect, useState } from "react";
import { gsap } from "gsap";

export default function Cursor({ app, isMainScreen }) {
  const [cursorLabel, setCursorLabel] = useState("");
  const [isCursorLabelVisible, setIsCursorLabelVisible] = useState(false);

  useEffect(() => {
    const cursor = document.querySelector(".cursor");
    let pos = { x: window.innerWidth + 25, y: window.innerHeight / 2 };
    let mouse = { x: pos.x, y: pos.y };
    let mouseDown = 0;
    const speed = 0.15;
    const fpms = 60 / 1000;
    let xSet = gsap.quickSetter(cursor, "x", "px");
    let ySet = gsap.quickSetter(cursor, "y", "px");

    // Handle click on 3d model link
    const handleClick = (event) => {
      if (document.querySelector(".model-link__link--hovered")) {
        const link = document
          .querySelector(".model-link__link--hovered")
          .getAttribute("href");
        window.location.href = link;
      }
      if (event.target.getAttribute("rollover")) {
        showCursorLinkLabel(event.target);
      }
    };

    // Handle cursor label while model link hovered
    const showCursorModelLinkLabel = () => {
      const imageAlt = document
        .querySelector(".model-link__link--hovered .model-link__image")
        .getAttribute("alt");
      setCursorLabel(imageAlt);
      setIsCursorLabelVisible(true);
    };

    // Handle cursor label while link with prop "rollover" hovered
    const showCursorLinkLabel = (linkEl) => {
      if (linkEl.getAttribute("rollover")) {
        const linkWithRollover = linkEl.getAttribute("rollover");
        setCursorLabel(linkWithRollover);
        setIsCursorLabelVisible(true);
      }
    };

    const hideCursorLabel = () => {
      setIsCursorLabelVisible(false);
    };

    // On mouse move funcions
    const handleMouseMove = (event) => {
      let x = event.x;
      let y = event.y;

      // Detect model links v1 (non-perfect solution)

      // const image = document.querySelector(".model-link__image");
      // let rect = image.getBoundingClientRect();
      //const modelLinks = [...app.current.querySelectorAll(".model-link__link")];
      // for (const modelLink of modelLinks) {
      //   let rect = modelLink.getBoundingClientRect();
      //   if (
      //     // x > rect.left &&
      //     // x < rect.right &&
      //     // y > rect.top &&
      //     // y < rect.bottom
      //   ) {
      //     modelLink.classList.add("model-link__link--hovered");
      //   } else {
      //     modelLink.classList.remove("model-link__link--hovered");
      //   }
      // }

      // Detect model links v2
      const modelLinks = [...app.current.querySelectorAll(".model-link__link")];
      const hoveredLayers = document.elementsFromPoint(x, y);

      const findModelLinksUnderCursor = () => {
        for (const hoveredLayer of hoveredLayers) {
          if (hoveredLayer.classList.contains("model-link__shape")) {
            for (const modelLink of modelLinks) {
              if (modelLink === hoveredLayer.parentNode) {
                modelLink.classList.add("model-link__link--hovered");
              } else {
                modelLink.classList.remove("model-link__link--hovered");
              }
              // Handle compass modell pair links
              if (modelLink.getAttribute("link-pair")) {
                if (
                  modelLink.getAttribute("link-pair") ===
                  hoveredLayer.parentNode.getAttribute("link-pair")
                ) {
                  modelLink.classList.add("model-link__link--hovered");
                } else {
                  modelLink.classList.remove("model-link__link--hovered");
                }
              }
            }
          }
        }
      };

      const removeHoverClass = () => {
        modelLinks.forEach((modelLink) =>
          modelLink.classList.remove("model-link__link--hovered")
        );
      };

      if (
        hoveredLayers.find((el) => {
          return el.classList.contains("model-link__shape");
        })
      ) {
        findModelLinksUnderCursor();
      } else {
        removeHoverClass();
      }

      // Custom cursor
      mouse.x = x;
      mouse.y = y;

      // Define elements which triggers cursor animation on hover
      let allHoveredLayers = event.composedPath();
      const hoverElementTypes = ["A", "BUTTON", "INPUT", "SELECT", "TEXTAREA"];
      //const hoverElementClasses = ["model-link__shape"];
      let isHoveredLayerLink = allHoveredLayers.filter((layer) => {
        return hoverElementTypes.includes(layer.tagName);
      });

      // Cursor animation on hover
      if (mouseDown === 0) {
        if (isHoveredLayerLink.length) {
          cursor.classList.add("cursor--active");
          showCursorLinkLabel(isHoveredLayerLink[0]);
        } else if (
          document.querySelector(".model-link__link--hovered") &&
          document.querySelector(".cursor__label")
        ) {
          cursor.classList.add("cursor--active");
          showCursorModelLinkLabel();
        } else {
          if (document.querySelector(".cursor__label")) {
            hideCursorLabel();
          }
          cursor.classList.remove("cursor--active");
        }
      }
    };

    // Cursor animation on click
    const handleMouseDown = (event) => {
      mouseDown = 1;
      !cursor.classList.contains("cursor--active") &&
        cursor.classList.add("cursor--active");
    };

    const handleMouseUp = (event) => {
      mouseDown = 0;
      cursor.classList.contains("cursor--active") &&
        cursor.classList.remove("cursor--active");
    };

    gsap.ticker.add((time, deltaTime) => {
      let delta = deltaTime * fpms;
      let dt = 1.0 - Math.pow(1.0 - speed, delta);
      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="cursor">
      <div className="cursor__inner"></div>
      <div className="cursor__outer"></div>
      <div
        className={`cursor__label ${
          isCursorLabelVisible ? "cursor__label--visible" : ""
        }`}
      >
        {cursorLabel}
      </div>
    </div>
  );
}
