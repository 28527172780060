import { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { ReactComponent as ArrowUp } from "../media/arrow-up.svg";
import { recommendationsData } from "./RecommendationsData";

export default function Dropdown({
  selectEl,
  isIntroVideoScreen,
  setIsRecommendationsScreen,
  setBoxData,
  setIsMainScreen,
  setIsModelLinkOn,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const listRevealAnimation = () => {
    const tl = gsap.timeline();
    gsap.set(".dropdown__list-item", { x: 0, alpha: 1 });
    tl.to(".dropdown__list-container ", {
      duration: 0.2,
      scaleY: 1,
      transformOrigin: "bottom",
    });
    tl.from(".dropdown__list-item", {
      x: -70,
      alpha: 0,
      stagger: {
        each: 0.07,
      },
    });
  };

  const listHideAnimation = () => {
    const tl = gsap.timeline();
    tl.to(".dropdown__list-item", {
      x: -70,
      alpha: 0,
      stagger: {
        from: "end",
        each: 0.07,
      },
    });
    tl.to(
      ".dropdown__list-container ",
      {
        duration: 0.3,
        scaleY: 0,
        transformOrigin: "bottom",
        onComplete: () => {
          setIsOpen(false);
          setIsModelLinkOn(true);
        },
      },
      "-=0.2"
    );
  };

  const toggleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
      setIsModelLinkOn(false);
      listRevealAnimation();
    } else {
      listHideAnimation();
    }
  };

  const handleRecommendationScreen = (
    pageTitle,
    pageBg,
    pageColorSheme,
    buttonLink,
    boxes
  ) => {
    setBoxData((prevFormData) => {
      return {
        ...prevFormData,
        pageTitle: pageTitle,
        pageBg: pageBg,
        pageColorSheme: pageColorSheme ? pageColorSheme : "dark",
        buttonLink: buttonLink ? buttonLink : "",
        boxes: boxes ? boxes : [],
      };
    });

    const setMainScreenFalse = () => {
      setIsMainScreen(false);
    };

    setIsRecommendationsScreen(true);
    setTimeout(setMainScreenFalse, 1500);
  };

  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;
  // };

  return (
    <button
      ref={selectEl}
      className={`dropdown ${isIntroVideoScreen ? "dropdown--hidden" : ""}`}
      aria-expanded={isOpen ? "true" : "false"}
      onClick={toggleOpen}
    >
      <div
        className={`dropdown__list-container ${
          isOpen ? "" : "dropdown__list-container--hidden"
        }`}
      >
        <div className="dropdown__list">
          <ul className="dropdown__list">
            {recommendationsData.map((recommendationData, index) => (
              <li
                className="dropdown__list-item"
                key={index}
                tabIndex="0"
                onClick={(event) =>
                  handleRecommendationScreen(
                    recommendationData.value,
                    recommendationData.pageBg,
                    recommendationData.pageColorSheme,
                    recommendationData.buttonLink,
                    recommendationData.boxes
                  )
                }
              >
                {recommendationData.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="dropdown__header">
        I am
        <ArrowUp
          className={`dropdown__arrow ${
            isOpen ? "dropdown__arrow--active" : ""
          }`}
        />
      </div>
    </button>
  );
}
