import { useState, useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import {
  Float,
  useGLTF,
  Html,
  View,
  Text,
  OrthographicCamera,
  PresentationControls,
} from "@react-three/drei";
import { ModelPresentationControls } from "./ModelPresentationControls";
import HeartLinks from "./HeartLinks";
import gsap from "gsap";

export default function Heart({
  isPlaying,
  isMainScreen,
  isRecommendationsScreen,
  setIsInitialLoadDone,
  isInitialLoadDone,
  isModelLinkOn,
}) {
  const { size } = useThree();
  const { nodes, materials } = useGLTF("./models/heart/heart.glb");
  const heart = useRef();
  const heartGroup = useRef();
  const heartText = useRef();

  useEffect(() => {
    if (!isPlaying && !isInitialLoadDone) {
      heartInitAnimation();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isInitialLoadDone) {
      isRecommendationsScreen ? heartOutAnimation() : heartInAnimation();
    }
  }, [isRecommendationsScreen]);

  const heartInitAnimation = () => {
    const heartRotation = heart.current.rotation;
    const heartGroupPosition = heartGroup.current.position;
    const heartTextPosition = heartText.current.position;
    gsap.fromTo(
      heartGroupPosition,
      {
        x: 0,
      },
      {
        x: modelPosition.x,
        delay: 1.5,
        duration: 2.5,
        ease: "power2.inOut",
      }
    );
    gsap.to(heartRotation, {
      z: 0.3,
      y: 0.1,
      duration: 0.8,
      delay: 1.8,
      ease: "power2.inOut",
    });
    gsap.to(heartRotation, {
      z: 0,
      y: 0,
      duration: 0.8,
      delay: 2.7,
      ease: "power2.inOut",
    });
    gsap.fromTo(
      heartTextPosition,
      {
        x: -12,
      },
      {
        x: textPosition.x,
        duration: 3,
        delay: 1.5,
        ease: "power2.inOut",
      }
    );

    // gsap.set(heartPosition, {
    //   x: 2.5,
    // });

    // gsap.to(heartPosition, {
    //   x: -12,
    //   duration: 0.8,
    //   delay: 0.3,
    //   ease: "power2.inOut",
    // });
    // gsap.to(heartTextPosition, {
    //   x: -12,
    //   duration: 1,
    //   delay: 0.35,
    //   ease: "power2.inOut",
    // });
  };

  const heartOutAnimation = () => {
    const heartRotation = heart.current.rotation;
    const heartPosition = heart.current.position;
    const heartTextPosition = heartText.current.position;
    gsap.to(heartRotation, {
      z: 0.4,
      y: 0.2,
      duration: 0.8,
      ease: "power2.inOut",
    });
    gsap.to(heartPosition, {
      x: -12,
      duration: 0.8,
      delay: 0.3,
      ease: "power2.inOut",
    });
    gsap.to(heartTextPosition, {
      x: -12,
      duration: 1,
      delay: 0.35,
      ease: "power2.inOut",
    });
  };

  const heartInAnimation = () => {
    const tl = gsap.timeline();
    const heartRotation = heart.current.rotation;
    const heartPosition = heart.current.position;
    const heartTextPosition = heartText.current.position;
    gsap.to(heartRotation, {
      z: 0,
      y: 0,
      duration: 0.8,
      ease: "power2.inOut",
    });
    gsap.to(heartPosition, {
      x: 0,
      duration: 0.8,
      delay: 0.3,
      ease: "power2.inOut",
    });
    gsap.to(heartTextPosition, {
      x: 0,
      duration: 1,
      delay: 0.35,
      ease: "power2.inOut",
    });
  };

  const [modelSize, setModelSize] = useState(0.00153);
  const [fontSize, setFontSize] = useState(0.25);

  const calculateSize = () => {
    if (size.width > 1365) {
      setModelSize(0.00153);
      setFontSize(0.25);
    } else {
      setModelSize((size.width / 10000) * 0.011);
      setFontSize(size.width / 10000 + 0.11);
    }
  };

  const [modelPosition, setModelPosition] = useState({
    x: -2.5,
    y: -0.2,
  });

  const [textPosition, setTextPosition] = useState({
    x: 0,
    y: -1.6,
  });

  const calculatePosition = () => {
    if (size.width > 1365) {
      setModelPosition({
        x: -2.5,
        y: -0.2,
      });
      setTextPosition({
        x: 0,
        y: -1.6,
      });
    } else if (size.width > 960 && size.width < 1365) {
      setModelPosition({
        x: -size.width / 1000 - 1,
        y: -0.2,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.2,
      });
    } else {
      setModelPosition({
        x: 0,
        y: 1.5,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.2,
      });
    }
  };

  useEffect(() => {
    calculateSize();
    calculatePosition();
  }, [size.width]);

  return (
    <ModelPresentationControls
      polar={[-0.2, 0.15]}
      azimuth={[-0.2, 0.2]}
      config={{ mass: 4, tension: 75 }}
      snap={{ mass: 10, tension: 400 }}
      speed={1}
      cursor={false}
    >
      <group
        ref={heartGroup}
        position-y={modelPosition.y}
        position-x={modelPosition.x}
      >
        <Float speed={1.1} rotationIntensity={0.6}>
          <mesh
            geometry={nodes.Logo_3D.geometry}
            material={materials.Logo_3D}
            //scale={0.00155}
            scale={modelSize > 0.0008 ? modelSize : 0.0008}
            rotation={[Math.PI / 2, 0, 0]}
            castShadow
            ref={heart}
            //onPointerEnter={handleModelHoverEnter}
            //onPointerLeave={handleModelHoverLeave}
          >
            <HeartLinks isModelLinkOn={isModelLinkOn} />
          </mesh>
        </Float>
        <Text
          font="./fonts/work-sans-v18-latin-800.woff"
          color={"#000"}
          fontSize={fontSize > 0.19 ? fontSize : 0.19}
          fontWeight={800}
          textAlign={"center"}
          position={[0, textPosition.y < -1.15 ? textPosition.y : -1.152, 1]}
          rotation-y={0}
          ref={heartText}
          //maxWidth={2}
        >
          UK EVENTS INDUSTRY
        </Text>
      </group>
    </ModelPresentationControls>
  );
}

useGLTF.preload("./models/heart/heart.glb");
