import { useState, useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { Float, useGLTF, Text, PresentationControls } from "@react-three/drei";
import { ModelPresentationControls } from "./ModelPresentationControls";
import gsap from "gsap";
import CompassLinks from "./CompassLinks";

export default function Compass({
  isPlaying,
  isMainScreen,
  isRecommendationsScreen,
  setIsInitialLoadDone,
  isInitialLoadDone,
  isModelLinkOn,
}) {
  const { size } = useThree();
  const { nodes, materials } = useGLTF("./models/compass/compass.glb");
  const compass = useRef();
  const compassGroup = useRef();
  const compassText = useRef();

  useEffect(() => {
    if (!isPlaying && !isInitialLoadDone) {
      compassInitAnimation();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isInitialLoadDone) {
      isRecommendationsScreen ? compassOutAnimation() : compassInAnimation();
    }
  }, [isRecommendationsScreen]);

  const compassInitAnimation = () => {
    const compassRotation = compass.current.rotation;
    const compassGroupPosition = compassGroup.current.position;
    const compassTextPosition = compassText.current.position;
    const compassScale = compass.current.scale;
    gsap.fromTo(
      compassGroupPosition,
      {
        x: 0,
        y: -0.36,
        z: 3,
      },
      {
        x: modelPosition.x,
        y: modelPosition.y,
        z: 0,
        delay: 1.5,
        duration: 2.5,
        ease: "power2.inOut",
      }
    );
    gsap.fromTo(
      compassScale,
      {
        x: 0.00135,
        y: 0.00135,
        z: 0.00135,
      },
      {
        x: modelSize > 0.0009 ? modelSize : 0.0009,
        y: modelSize > 0.0009 ? modelSize : 0.0009,
        z: modelSize > 0.0009 ? modelSize : 0.0009,
        duration: 1.2,
        delay: 1.6,
        ease: "power2.inOut",
      }
    );
    gsap.to(compassRotation, {
      z: 0.4,
      x: 0.5,
      y: -1.2,
      duration: 0.8,
      delay: 1.8,
      ease: "power2.inOut",
    });
    gsap.to(compassRotation, {
      z: 0,
      x: 0,
      y: -Math.PI / 2,
      duration: 0.8,
      delay: 2.7,
      ease: "power2.inOut",
    });
    gsap.fromTo(
      compassTextPosition,
      {
        x: 12,
      },
      {
        x: textPosition.x,
        duration: 3,
        delay: 1.5,
        ease: "power2.inOut",
      }
    );
    // gsap.to(compassScale, {
    //   x: 0.0018,
    //   y: 0.0018,
    //   z: 0.0018,
    //   duration: 2.5,
    //   delay: 2,
    //   ease: "power2.inOut",
    // });

    //   gsap.to(compassRotation, {
    //     z: 0.7,
    //     x: 1,
    //     y: -2.2,
    //     duration: 0.8,
    //     ease: "power2.inOut",
    //   });
    //   gsap.to(compassPosition, {
    //     x: 12,
    //     duration: 0.8,
    //     delay: 0.3,
    //     ease: "power2.inOut",
    //   });
    //   gsap.to(compassTextPosition, {
    //     x: 12,
    //     duration: 1,
    //     delay: 0.35,
    //     ease: "power2.inOut",
    //   });
    // };
  };

  const compassOutAnimation = () => {
    const compassRotation = compass.current.rotation;
    const compassPosition = compass.current.position;
    const compassTextPosition = compassText.current.position;

    gsap.to(compassRotation, {
      z: 0.7,
      x: 1,
      y: -2.2,
      duration: 0.8,
      ease: "power2.inOut",
    });
    gsap.to(compassPosition, {
      x: 12,
      duration: 0.8,
      delay: 0.3,
      ease: "power2.inOut",
    });
    gsap.to(compassTextPosition, {
      x: 12,
      duration: 1,
      delay: 0.35,
      ease: "power2.inOut",
    });
  };

  const compassInAnimation = () => {
    const compassRotation = compass.current.rotation;
    const compassPosition = compass.current.position;
    const compassTextPosition = compassText.current.position;
    gsap.to(compassRotation, {
      z: 0,
      x: 0,
      y: -Math.PI / 2,
      duration: 0.8,
      ease: "power2.inOut",
    });
    gsap.to(compassPosition, {
      x: 0,
      duration: 0.8,
      delay: 0.3,
      ease: "power2.inOut",
    });
    gsap.to(compassTextPosition, {
      x: 0,
      duration: 1,
      delay: 0.35,
      ease: "power2.inOut",
    });
  };

  const [modelSize, setModelSize] = useState(0.0018);
  const [fontSize, setFontSize] = useState(0.25);

  const calculateSize = () => {
    if (size.width > 1365) {
      setModelSize(0.0018);
      setFontSize(0.25);
    } else {
      setModelSize((size.width / 10000) * 0.013);
      setFontSize(size.width / 10000 + 0.11);
    }
  };

  const [modelPosition, setModelPosition] = useState({
    x: 2.8,
    y: 0.15,
  });

  const [textPosition, setTextPosition] = useState({
    x: 0,
    y: -1.95,
  });

  const calculatePosition = () => {
    if (size.width > 1365) {
      setModelPosition({
        x: 2.8,
        y: 0.15,
      });
      setTextPosition({
        x: 0,
        y: -1.95,
      });
    } else if (size.width > 960 && size.width < 1365) {
      setModelPosition({
        x: size.width / 1000 + 1,
        y: 0.15,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.54,
      });
    } else {
      setModelPosition({
        x: 0,
        y: -1.05,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.2,
      });
    }
  };

  useEffect(() => {
    calculateSize();
    calculatePosition();
  }, [size.width]);

  return (
    <ModelPresentationControls
      polar={[-0.25, 0.2]}
      azimuth={[-0.2, 0.2]}
      // config={{ mass: 2, tension: 400 }}
      // snap={{ mass: 6, tension: 400 }}
      config={{ mass: 4, tension: 75 }}
      snap={{ mass: 10, tension: 400 }}
      speed={1}
      cursor={false}
    >
      <group
        ref={compassGroup}
        position-y={modelPosition.y}
        position-x={modelPosition.x}
      >
        <Float speed={1.1} rotationIntensity={0.6}>
          <mesh
            geometry={nodes.Left_Top.geometry}
            material={materials.Right_Top}
            //scale={0.0018}
            scale={modelSize > 0.0009 ? modelSize : 0.0009}
            rotation={[0, -Math.PI / 2, 0]}
            castShadow
            ref={compass}
          >
            <CompassLinks isModelLinkOn={isModelLinkOn} />
          </mesh>
        </Float>
        <Text
          font="./fonts/work-sans-v18-latin-800.woff"
          color={"#000"}
          fontSize={fontSize > 0.19 ? fontSize : 0.19}
          fontWeight={800}
          textAlign={"center"}
          position={[0, textPosition.y < -1.25 ? textPosition.y : -1.25, 1]}
          rotation-y={0}
          ref={compassText}
        >
          INDUSTRY COMMUNITY
        </Text>
      </group>
    </ModelPresentationControls>
  );
}

useGLTF.preload("./models/compass/compass.glb");
