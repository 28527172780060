import { useState, useRef, useEffect } from "react";
import { Route } from "react-router-dom";
import { useFrame, useThree } from "@react-three/fiber";
import {
  Text,
  Html,
  Float,
  useGLTF,
  useTexture,
  useAspect,
  softShadows,
  OrbitControls,
  BakeShadows,
} from "@react-three/drei";
import gsap from "gsap";
import Heart from "./Heart";
import Compass from "./Compass";

softShadows({
  frustum: 4.1,
  size: 0.04,
  near: 9.5,
  samples: 12,
  rings: 11,
});

export default function Experience({
  isPlaying,
  isMainScreen,
  isRecommendationsScreen,
  setIsRecommendationsScreen,
  isInitialLoadDone,
  setIsInitialLoadDone,
  isModelLinkOn,
}) {
  const { size } = useThree();
  const bgMesh = useRef();
  const bgImage = useTexture("./textures/main-bg.jpg");

  const scale = useAspect(
    1920, // Pixel-width
    1080, // Pixel-height
    1 // Optional scaling factor
  );

  return (
    <>
      <directionalLight
        castShadow={size.width > 966 ? true : false}
        position={[0, 1, 8]}
        intensity={0.25}
        shadow-camera-far={12}
        shadow-camera-top={1.2}
        shadow-camera-right={5.5}
        shadow-camera-bottom={-1.8}
        shadow-camera-left={-5.5}
      />
      <ambientLight intensity={0.5} />
      <Heart
        isPlaying={isPlaying}
        isMainScreen={isMainScreen}
        isRecommendationsScreen={isRecommendationsScreen}
        setIsRecommendationsScreen={setIsRecommendationsScreen}
        isInitialLoadDone={isInitialLoadDone}
        setIsInitialLoadDone={setIsInitialLoadDone}
        isModelLinkOn={isModelLinkOn}
      />
      <Compass
        isPlaying={isPlaying}
        isMainScreen={isMainScreen}
        isRecommendationsScreen={isRecommendationsScreen}
        isInitialLoadDone={isInitialLoadDone}
        setIsInitialLoadDone={setIsInitialLoadDone}
        isModelLinkOn={isModelLinkOn}
      />

      {size.width > 960 && (
        <mesh ref={bgMesh} scale={scale} position={[0, 0, -2.5]} receiveShadow>
          <planeGeometry />
          <meshStandardMaterial map={bgImage} />
        </mesh>
      )}
    </>
  );
}
