import { useRef, useEffect } from "react";
import gsap from "gsap";
import Dropdown from "./Dropdown";
import { ReactComponent as SoundOffIcon } from "../media/icon-mute.svg";
import { ReactComponent as SoundOnIcon } from "../media/icon-unmute.svg";
import { ReactComponent as SkipIcon } from "../media/icon-skip.svg";
import { ReactComponent as BackIcon } from "../media/icon-play.svg";
import { ReactComponent as HomeIcon } from "../media/icon-home.svg";
import soundOnEffect from "../media/sound-effect.mp3";

export default function Footer({
  isInitScreen,
  isIntroVideoScreen,
  isMainScreen,
  isSingleModelPage,
  isRecommendationsScreen,
  isMuted,
  setIsMuted,
  setIsPlaying,
  handleVideoEnd,
  setIsRecommendationsScreen,
  setBoxData,
  setIsMainScreen,
  setIsModelLinkOn,
}) {
  const soundIcon = useRef();
  const skipIcon = useRef();
  const homeIcon = useRef();
  const backIcon = useRef();
  const selectEl = useRef();
  const btnAudio = new Audio(soundOnEffect);

  useEffect(() => {
    isIntroVideoScreen && footerInAnimation();
    isMainScreen && !isSingleModelPage
      ? selectInAnimation()
      : selectOutAnimation();
    isIntroVideoScreen
      ? iconInAnimation(skipIcon.current)
      : iconOutAnimation(skipIcon.current);
    isMainScreen
      ? iconInAnimation(homeIcon.current)
      : iconOutAnimation(homeIcon.current);
    isRecommendationsScreen
      ? iconInAnimation(backIcon.current)
      : iconOutAnimation(backIcon.current);
  }, [isIntroVideoScreen, isMainScreen]);

  const footerInAnimation = () => {
    gsap.from(soundIcon.current, {
      duration: 1,
      alpha: 0,
      y: 100,
    });
    gsap.from(skipIcon.current, {
      duration: 1,
      alpha: 0,
      y: 100,
    });
  };

  const selectInAnimation = () => {
    gsap.fromTo(
      selectEl.current,
      {
        alpha: 0,
        y: 100,
      },
      {
        duration: 1,
        alpha: 1,
        y: 0,
      }
    );
  };

  const selectOutAnimation = () => {
    gsap.to(selectEl.current, {
      duration: 1,
      alpha: 0,
      y: 100,
    });
  };

  const iconInAnimation = (icon) => {
    gsap.fromTo(
      icon,
      {
        alpha: 0,
        y: 100,
      },
      {
        duration: 1,
        alpha: 1,
        y: 0,
      }
    );
  };

  const iconOutAnimation = (icon) => {
    gsap.to(icon, {
      duration: 1,
      alpha: 0,
      y: 100,
    });
  };

  // Check if "muted" is saved on local storage on first load
  useEffect(() => {
    if (localStorage.getItem("muted")) {
      setIsMuted(JSON.parse(localStorage.getItem("muted")));
    } else {
      localStorage.setItem("muted", "false");
    }
  }, []);

  // Handle mute/unmute button
  const handleMute = () => {
    setIsMuted((isMuted) => !isMuted);
    localStorage.setItem("muted", JSON.stringify(!isMuted));
    !isMuted && btnAudio.play();
  };

  const handleSkip = (event) => {
    event.currentTarget.disabled = true;
    handleVideoEnd();
  };

  const handleBack = (event) => {
    event.currentTarget.disabled = true;
    setIsRecommendationsScreen(false);
    setIsMainScreen(true);
  };

  return (
    <footer className="footer">
      <div
        className={`container container--footer ${
          isInitScreen ? "container--hidden" : ""
        }`}
      >
        <div className="footer__content">
          <button
            ref={soundIcon}
            className="footer__button footer__button--sound"
            aria-label={`${isMuted ? "Disable sounds" : "Enable sounds"}`}
            onClick={handleMute}
            rollover={`${isMuted ? "UNMUTE" : "MUTE"}`}
          >
            {isMuted ? <SoundOnIcon /> : <SoundOffIcon />}
          </button>
          <Dropdown
            selectEl={selectEl}
            isIntroVideoScreen={isIntroVideoScreen}
            setIsRecommendationsScreen={setIsRecommendationsScreen}
            setBoxData={setBoxData}
            setIsMainScreen={setIsMainScreen}
            setIsModelLinkOn={setIsModelLinkOn}
          />
          <div className="footer__buttons">
            <button
              ref={skipIcon}
              className="footer__button footer__button--control footer__button--skip"
              aria-label={"Skip video"}
              onClick={handleSkip}
              rollover={"SKIP"}
            >
              <SkipIcon />
            </button>
            <button
              ref={homeIcon}
              className="footer__button footer__button--control footer__button--home"
              aria-label={"Go to Homepage"}
            >
              <a href="https://www.thepowerofevents.org/" rollover={"HOME"}>
                <HomeIcon />
              </a>
            </button>
            <button
              ref={backIcon}
              className="footer__button footer__button--control footer__button--back"
              aria-label={"Back to Intro Page"}
              onClick={handleBack}
              rollover={"BACK"}
            >
              <BackIcon />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}
